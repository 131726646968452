// jQuery は vendor/bower-assets のものを使えない。
// 他の jQuery プラグインが node_modules からインポートするので。
const jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

require('bootstrap');

// npm i --save inputmask@~3.2.2 punycode@~1.3.0 yii2-pjax@~2.0.1
// こうしたいけど inputmask の 3.x はもうないし、Yii が依存するバージョンの変更を追うのはイヤなので保留
const Inputmask = require('../../vendor/bower-asset/inputmask');
window.Inputmask = Inputmask;
const punycode = require('../../vendor/bower-asset/punycode');
window.punycode = punycode;

require('../../vendor/yiisoft/yii2/assets/yii');
require('../../vendor/yiisoft/yii2/assets/yii.captcha');
require('../../vendor/yiisoft/yii2/assets/yii.validation');
require('../../vendor/yiisoft/yii2/assets/yii.activeForm');
require('../../vendor/yiisoft/yii2/assets/yii.gridView');
require('../../vendor/bower-asset/yii2-pjax');

// require('../../vendor/yiisoft/yii2-authclient/src/assets/authchoice');
