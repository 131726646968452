require("./dist/inputmask/inputmask.date.extensions");
require("./dist/inputmask/inputmask.extensions");
require("./dist/inputmask/inputmask.numeric.extensions");
require("./dist/inputmask/inputmask.phone.extensions");

// require("./dist/inputmask/phone-codes/phone-be");
// require("./dist/inputmask/phone-codes/phone-nl");
// require("./dist/inputmask/phone-codes/phone-ru");
// require("./dist/inputmask/phone-codes/phone-uk");
// require("./dist/inputmask/phone-codes/phone");

module.exports = require("./dist/inputmask/inputmask.js");
