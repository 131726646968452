(function ($) {
'use strict';

$ = $ && $.hasOwnProperty('default') ? $['default'] : $;

var asyncGenerator = function () {
  function AwaitValue(value) {
    this.value = value;
  }

  function AsyncGenerator(gen) {
    var front, back;

    function send(key, arg) {
      return new Promise(function (resolve, reject) {
        var request = {
          key: key,
          arg: arg,
          resolve: resolve,
          reject: reject,
          next: null
        };

        if (back) {
          back = back.next = request;
        } else {
          front = back = request;
          resume(key, arg);
        }
      });
    }

    function resume(key, arg) {
      try {
        var result = gen[key](arg);
        var value = result.value;

        if (value instanceof AwaitValue) {
          Promise.resolve(value.value).then(function (arg) {
            resume("next", arg);
          }, function (arg) {
            resume("throw", arg);
          });
        } else {
          settle(result.done ? "return" : "normal", result.value);
        }
      } catch (err) {
        settle("throw", err);
      }
    }

    function settle(type, value) {
      switch (type) {
        case "return":
          front.resolve({
            value: value,
            done: true
          });
          break;

        case "throw":
          front.reject(value);
          break;

        default:
          front.resolve({
            value: value,
            done: false
          });
          break;
      }

      front = front.next;

      if (front) {
        resume(front.key, front.arg);
      } else {
        back = null;
      }
    }

    this._invoke = send;

    if (typeof gen.return !== "function") {
      this.return = undefined;
    }
  }

  if (typeof Symbol === "function" && Symbol.asyncIterator) {
    AsyncGenerator.prototype[Symbol.asyncIterator] = function () {
      return this;
    };
  }

  AsyncGenerator.prototype.next = function (arg) {
    return this._invoke("next", arg);
  };

  AsyncGenerator.prototype.throw = function (arg) {
    return this._invoke("throw", arg);
  };

  AsyncGenerator.prototype.return = function (arg) {
    return this._invoke("return", arg);
  };

  return {
    wrap: function (fn) {
      return function () {
        return new AsyncGenerator(fn.apply(this, arguments));
      };
    },
    await: function (value) {
      return new AwaitValue(value);
    }
  };
}();





var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

'use strict';

var SpNav = function () {
  function SpNav() {
    var _this = this;

    classCallCheck(this, SpNav);

    $(function () {
      _this.nav = $('#sp-nav');
      _this.btn = $('[aria-controls="sp-nav"]');

      _this.onWindow();
      _this.onBtn();
    });
  }

  createClass(SpNav, [{
    key: 'onWindow',
    value: function onWindow() {
      var _this2 = this;

      $(window).on('resize', function () {
        _this2.close();
        _this2.unLockWindowScroll();
      });
    }
  }, {
    key: 'onBtn',
    value: function onBtn() {
      var _this3 = this;

      this.btn.click(function () {
        var expanded = _this3.btn.attr('aria-expanded');
        if ('false' === expanded) {
          _this3.open();
          _this3.lockWindowScroll();
        } else {
          _this3.close();
          _this3.unLockWindowScroll();
        }
      });
    }
  }, {
    key: 'open',
    value: function open() {
      this.btn.attr('aria-expanded', 'true');
      this.nav.attr('aria-hidden', 'false');
    }
  }, {
    key: 'close',
    value: function close() {
      this.btn.attr('aria-expanded', 'false');
      this.nav.attr('aria-hidden', 'true');
    }
  }, {
    key: 'lockWindowScroll',
    value: function lockWindowScroll() {
      $('body').css('overflow', 'hidden');
    }
  }, {
    key: 'unLockWindowScroll',
    value: function unLockWindowScroll() {
      $('body').css('overflow', '');
    }
  }]);
  return SpNav;
}();

'use strict';

new SpNav();

}(jQuery));
